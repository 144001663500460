export const YOUTUBE_CHANNEL_LINK = "https://www.youtube.com/channel/UCbeXH1XWDsSVL63-d0E43pw";
export const PRIMARY_DOMEN = "https://ohotaktiv.ru";
export const NO_PHOTO_PATH = PRIMARY_DOMEN + "/upload/no_photo.png";
export const OHOTAKTIV_API_PROD = "https://api.ohotaktiv.ru/api/v2";
export const OHOTAKTIV_API_DEV = "https://dev.ohotaktiv.tech/api/v2";
export const routes = [
  "about",
  "auth",
  "blog",
  "brands",
  "bitrix",
  "cabinet",
  "card",
  "catalog",
  "collection",
  "contacts",
  "delivery",
  "faq",
  "highlight",
  "license",
  "liquidation",
  "new",
  "opt",
  "partners",
  "pay",
  "policy",
  "policymobile",
  "return",
  "sales",
  "search",
  "shops",
  "tags",
  "vacancy",
  "upload",
  "cart",
  "order",
  "result",
  "remington",
  "admin",
  "rules-for-posting-reviews",
  "oprosnik",
  "promo-page",
  "move",
  "preorder",
  "license-department",
  "retail-store-promotions",
  "live",
  "black-friday",
  "mobile",
  "exposition",
  "mobile-app",
  "public",
  "images",
  "pictures",
  "rules-for-comission",
];
